import { FC } from 'react'
import { AccordionHeaderProps, Button } from '@magal/components'
import { ChevronDown, ChevronUp } from '@magal/icons'
import { styled } from '@magal/styles'

const AccordionHeadingStyled = styled(Button, {
  cursor: 'pointer',
  width: '100%',
  color: '$green_09',
  display: 'grid',
  gridAutoFlow: 'column',
  projectFont: 'body01',
  textTransform: 'capitalize',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'left',
  padding: '$16',
  variants: {
    isOpen: {
      true: {
        borderRadius: '$r2 $r2 0 0',
        textDecoration: 'underline',
      },
    },
  },
})

export const AccordionHeading: FC<
  AccordionHeaderProps & { title?: string }
> = ({ title, isOpen, ...restProps }) => {
  return (
    <AccordionHeadingStyled {...restProps} isOpen={isOpen}>
      <span>{title}</span>
      {isOpen ? <ChevronUp /> : <ChevronDown />}
    </AccordionHeadingStyled>
  )
}
