import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createSectionSampleProjection } from '../sections/createSectionSampleProjection'
import { createSectionHeroProjection } from '../sections/createSectionHeroProjection'
import { createSectionCollectionsSliderProjection } from '../sections/createSectionCollectionsSliderProjection'
import { createSectionCardsSliderProjection } from '../sections/createSectionCardsSliderProjection'
import { createSectionFaqProjection } from '../sections/createSectionFaqProjection'
import { createSectionThreeFeaturesProjection } from '../sections/createSectionThreeFeaturesProjection'
import { createSectionTrustpilotBannerProjection } from '../sections/createSectionTrustpilotBannerProjection'
import { createSectionQuotesProjection } from '../sections/createSectionQuotesProjection'
import { createSectionNewsletterProjection } from '../sections/createSectionNewsletterProjection'
import { createSectionCollectionProjection } from '../sections/createSectionCollectionProjection'
import { createSectionValueAndStyleProjection } from '../sections/createSectionValueAndStyleProjection'
import { createSectionImageAndContentProjection } from '../sections/createSectionImageAndContentProjection'
import { createSectionGreenProjection } from '../sections/createSectionGreenProjection'
import { createSectionProductRecommendationsProjection } from '../sections/createSectionProductRecommendationsProjection'
import { createSectionFourFeaturesProjection } from '../sections/createSectionFourFeaturesProjection'
import { createSectionRecentlyViewedProjection } from '../sections/createSectionRecentlyViewedProjection'
import { createSectionJournalQuoteProjection } from '../sections/createSectionJournalQuoteProjection'
import { createSectionJournalImageProjection } from '../sections/createSectionJournalImageProjection'
import { createSectionJournalColumnProjection } from '../sections/createSectionJournalColumnProjection'
import { createSectionJournalPostsAuthorProjection } from '../sections/createSectionJournalPostsAuthorProjection'
import { createSectionJournalLandingPageProjection } from '../sections/createSectionJournalLandingPageProjection'
import { createSectionUtilityPageProjection } from '../sections/createSectionUtilityPageProjection'
import { createSectionLandscapeImageProjection } from '../sections/createSectionLandscapeImage'
import { createSectionJournalRelatedProductsProjection } from '../sections/createSectionJournalRelatedProductsProjection'
import { createSectionUtilityPageHeaderProjection } from '../sections/createSectionUtilityPageHeaderProjection'
import { createSectionFourSixtyInstagramFeedProjection } from '../sections/createSectionFourSixtyInstagramFeedProjection'

export const createPageBuilderProjection = (localeId: LocaleId) => groq`{
  _type == 'sectionSample' => ${createSectionSampleProjection(localeId)},
  _type == 'sectionHero' =>  ${createSectionHeroProjection(localeId)},
  _type == 'sectionCollectionsSlider' =>  ${createSectionCollectionsSliderProjection()},
  _type == 'sectionCardsSlider' =>  ${createSectionCardsSliderProjection(
    localeId,
  )},
  _type == 'sectionFaq' =>  ${createSectionFaqProjection(localeId)},
  _type == 'sectionThreeFeatures' =>  ${createSectionThreeFeaturesProjection(
    localeId,
  )},
  _type == 'sectionTrustpilotBanner' =>  ${createSectionTrustpilotBannerProjection(
    localeId,
  )},
  _type == 'sectionQuotes' =>  ${createSectionQuotesProjection(localeId)},
  _type == 'sectionNewsletter' =>  ${createSectionNewsletterProjection(
    localeId,
  )},
  _type == 'sectionCollection' =>  ${createSectionCollectionProjection(
    localeId,
  )},
  _type == 'sectionValueAndStyle' =>  ${createSectionValueAndStyleProjection(
    localeId,
  )},
  _type == 'sectionImageAndContent' =>  ${createSectionImageAndContentProjection(
    localeId,
  )},
  _type == 'sectionGreen' =>  ${createSectionGreenProjection(localeId)},
  _type == 'sectionProductRecommendations' =>  ${createSectionProductRecommendationsProjection(
    localeId,
  )},
  _type == 'sectionFourFeatures' =>  ${createSectionFourFeaturesProjection(
    localeId,
  )},
  _type == 'sectionJournalQuote' =>  ${createSectionJournalQuoteProjection(
    localeId,
  )},
  _type == 'sectionJournalImage' =>  ${createSectionJournalImageProjection(
    localeId,
  )},
  _type == 'sectionJournalColumn' =>  ${createSectionJournalColumnProjection(
    localeId,
  )},
  _type == 'sectionJournalPostsAuthor' =>  ${createSectionJournalPostsAuthorProjection()},
  _type == 'sectionJournalLandingPage' =>  ${createSectionJournalLandingPageProjection(
    localeId,
  )},
  _type == 'sectionRecentlyViewed' =>  ${createSectionRecentlyViewedProjection(
    localeId,
  )},
  _type == 'SectionUtilityPageHeader' =>  ${createSectionUtilityPageHeaderProjection(
    localeId,
  )},
  _type == 'sectionUtilityPage' =>  ${createSectionUtilityPageProjection(
    localeId,
  )},
    _type == 'sectionLandscapeImage' =>  ${createSectionLandscapeImageProjection(
      localeId,
    )},
  _type == 'sectionJournalRelatedProducts' =>  ${createSectionJournalRelatedProductsProjection(
    localeId,
  )},
  _type == 'sectionFourSixtyInstagramFeed' =>  ${createSectionFourSixtyInstagramFeedProjection(
    localeId,
  )},
}`
