interface LineItem {
  item: string | number
  q: number
}

interface TripleItem {
  product?: {
    id: string
    name: string
    price: string | number
    variant: string | number
  }
  search?: string
}

declare global {
  interface Window {
    TriplePixel: (type: string, item: LineItem | TripleItem) => null
    TriplePixelData: {
      product: {
        id: string
        name: string
        price: string | number
        variant: string | number
      }
    }
  }
}

export const TripleAtc = (item: LineItem): void => {
  setTimeout(() => {
    if (!window.TriplePixel) return
    window.TriplePixel('AddToCart', item)
  }, 1000)
}

export const TriplePageLoad = (item: TripleItem): void => {
  setTimeout(() => {
    if (!window.TriplePixel) return
    try {
      window.TriplePixel('pageLoad', item)
      console.info('Triggered TriplePixel pageLoad', item)
    } catch (error) {
      console.error('Could not trigger TriplePixel pageLoad', error)
    }
  }, 1000)
}
