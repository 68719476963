import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@magal/configs'
import { createRichTextProjection } from '../projections/createRichTextProjection'

export const createSectionFaqProjection = (localeId: LocaleId) => {
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  return groq`{
    _key,
    _type,
    ${coalesceLocaleField('title', localeId)},
    ${coalesceLocaleField('description', localeId)}[]${RICH_TEXT_PROJECTION},
    faqs[] {
      ${coalesceLocaleField('title', localeId)},
      faq_item[] {
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField(
          'description',
          localeId,
        )}[]${RICH_TEXT_PROJECTION},
      }
    }
  }
`
}
